<template>
  <div class="BomReportProductCategory">
    <RadioGroup
      :model="store.bomReport.params"
      name="productCategory"
      :options="updatedOptions"
    />
  </div>
</template>

<script>
import RadioGroup from '../RadioGroup.vue'
export default {
  components: {
    RadioGroup
  },
  inject: [ 'store' ],
  computed: {
    allOption () {
      const value = this.values.filter(label => label === '674' || label === '673').join(',')
      const label = this.labels.filter(label => label === 'Bottoms' || label === 'Tops').join(' & ')
      return {
        value,
        label
      }
    },
    labels () {
      return this.store.seasonSelector.typeOptions.map((option) => {
        return option.label
      })
    },
    values () {
      return this.store.seasonSelector.typeOptions.map((option) => {
        return option.value
      })
    },
    options () {
      const baseOptions = this.store.seasonSelector.typeOptions
      return [
        this.allOption,
        ...baseOptions
      ]
    },
    updatedOptions () {
      const brand = this.store.bomReport.params.brands
      if (brand && brand.includes('483')) {
        return this.options
      } else {
        return this.options.filter(option => option.value !== '508')
      }
    }
  }
}
</script>
